// ANDR3W.ai - HomePage.tsx
// Using https://realfavicongenerator.net/ to generate favicons
import "/node_modules/augmented-ui/augmented-ui.min.css"; // Must be imported before other styles
import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Sidebar } from 'primereact/sidebar';
import { Menubar } from 'primereact/menubar';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Carousel } from 'primereact/carousel';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import './styles/HomePage.css';
import { ReactComponent as Logo } from './assets/Andr3w_ai_logo_2c2c2c.svg';
import { ReactComponent as FullWordLogo } from './assets/Andr3w-full-word-logo-d9d9d9.svg';
import Andr3wHUD from "./components/Andr3wHUD";
import Andr3wActiveWordLogo from "./components/Andr3wActiveWordLogo";
import Andr3wActiveFooterLogo from "./components/Andr3wActiveFooterLogo";
import Andr3wActiveFaceLogo from "./components/Andr3wActiveFaceLogo";
import HubSpotContactUsForm from "./components/HubSpotContactUsForm";
import CallDemoSidebar from "./components/CallDemoSidebar";
import TermsOfService from "./components/TermsOfService";
import SecretConsole from "./components/SecretConsole";

import "primereact/resources/themes/vela-orange/theme.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { ScrollPanel } from "primereact/scrollpanel";


declare global {
    interface Window {
        hbspt: any;
    }
}



const HomePage = () => {
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [demoSidebarVisible, setDemoSidebarVisible] = useState(false);
    const [selectedAcsPhoneNumber, setSelectedAcsPhoneNumber] = useState('');
    const [selectedDemoTitle, setSelectedDemoTitle] = useState('');

    const [tosDialogVisible, setTosDialogVisible] = useState(false);
    const [privacyDialogVisible, setPrivacyDialogVisible] = useState(false);
    const [secretConsoleVisible, setSecretConsoleVisible] = useState(false);

    const formContainerRef = useRef<HTMLDivElement>(null);
    const toast = useRef<Toast>(null);

    const homeRef = useRef(null);
    const demoRef = useRef(null);
    const industriesRef = useRef(null);
    const inboundCallsRef = useRef(null);
    const outboundCallsRef = useRef(null);
    const pricingRef = useRef(null);
    const testimonialRef = useRef(null);
    const faqRef = useRef(null);

    const openPrivacyPolicy = () => {
        setPrivacyDialogVisible(true);
    };

    const openTermsOfService = () => {
        setTosDialogVisible(true);
    };


    const handleFooterLogoClick = () => {
        setSecretConsoleVisible(true);
    };

    const handleDexisCommand = (title: string, phoneNumber: string) => {
        setSelectedDemoTitle(title);
        setSelectedAcsPhoneNumber(phoneNumber);
        setDemoSidebarVisible(true);
    };

    interface DemoPhoneNumbers {
        [key: string]: string;
    }

    const demoPhoneNumbers: DemoPhoneNumbers = {
        'Construction Software - SDR': '+14356567609',
        'Restaurant - Host': '+12533460450',
        'Travel Agency - Agent': '+15828521331',
        'Plumber - Office Assistant': '+14452014495',
        'DEXIS Demo': '+17703733001' // Dont display in demo list
    };

    // Define demo data with video URLs
    interface DemoData {
        title: string;
        description: string;
        phoneNumber: string;
        videoUrl: string;
    }

    const demoData: DemoData[] = [
        {
            title: "Travel Agency - Agent",
            description: "Travel Now",
            phoneNumber: demoPhoneNumbers["Travel Agency - Agent"],
            videoUrl: "/videos/travel_demo_timbuktu.mp4",
        },
        {
            title: "Restaurant - Host",
            description: "Sakura Japanese Steakhouse",
            phoneNumber: demoPhoneNumbers["Restaurant - Host"],
            videoUrl: "/videos/restaurant_demo.mp4",
        },
        {
            title: "Plumber - Office Assistant",
            description: "ABC Plumbing",
            phoneNumber: demoPhoneNumbers["Plumber - Office Assistant"],
            videoUrl: "/videos/plumbing_demo_phoenix.mp4",
        },
        {
            title: "Construction Software - SDR",
            description: "Projul",
            phoneNumber: demoPhoneNumbers["Construction Software - SDR"],
            videoUrl: "/videos/saas_demo_projul.mp4",
        },
    ];

    // Component for demo boxes
    interface DemoBoxProps {
        title: string;
        description: string;
        onCallClick: (title: string, phoneNumber: string) => void;
        demoVideoUrl: string;
    }

    const DemoBox: React.FC<DemoBoxProps> = ({ title, description, onCallClick, demoVideoUrl }) => {
        const [isPlayingVideo, setIsPlayingVideo] = useState(false);

        const handleListenClick = () => {
            setIsPlayingVideo(true);
        };

        const handleCloseClick = () => {
            setIsPlayingVideo(false);
        };

        return (
            <div className={`demo-box w-full p-3 common-aug-tv-mixin ${isPlayingVideo ? 'expanded' : ''}`}
                data-augmented-ui="tl-clip tr-clip br-clip bl-clip both"
                style={{ minHeight: isPlayingVideo ? '350px' : '350px', transition: 'all 0.5s ease' }}>

                {!isPlayingVideo && (
                    <div className="h-18rem">
                        <div className="p-4 h-full retro-tv border-round-3xl border-white-alpha-10">
                            <div className="mt-2">
                                <h3 className="text-xl font-bold mb-2">DEMO</h3>
                            </div>
                            <div className="mt-6">
                                <h3 className="text-xl font-bold mb-2">{title}</h3>
                                <p className="mt-6">{description}</p>
                            </div>

                        </div>
                        <div>
                            <Button className="p-0 mt-2" icon="pi pi-youtube" size="large" onClick={handleListenClick} />
                            <Button className="p-0 mt-2 ml-2" icon="pi pi-phone" size="large" onClick={() => onCallClick(title, demoPhoneNumbers[title])} />
                        </div>
                    </div>
                )}
                {isPlayingVideo && (
                    <div className="h-18rem">
                        <div className="p-4 h-full retro-tv border-round-3xl border-white-alpha-10 bg-blue-600">
                            <video width="100%" height="235px" controls autoPlay>
                                <source src={demoVideoUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        <div>
                            <Button className="p-0 mt-2" icon="pi pi-power-off" size="large" onClick={handleCloseClick} />
                        </div>
                    </div>
                )}

            </div>
        );
    };

    useEffect(() => {
        if (!window.hbspt) {
            console.log("HubSpot script not found, loading it now...");
            const script = document.createElement('script');
            script.src = "//js.hsforms.net/forms/embed/v2.js";
            script.async = true;
            script.onload = () => {
                console.log("HubSpot script loaded successfully");
            };
            script.onerror = (error) => {
                console.error("Failed to load HubSpot script:", error);
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to load contact form script', life: 3000 });
            };
            document.body.appendChild(script);
        } else {
            console.log("HubSpot script already loaded");
        }
    }, []);


    const openContactForm = () => {
        setSidebarVisible(true);
    };

    const renderDebugForm = () => (
        <form className="p-fluid">
            <div className="field">
                <label htmlFor="name">Name</label>
                <InputText id="name" type="text" />
            </div>
            <div className="field">
                <label htmlFor="email">Email</label>
                <InputText id="email" type="email" />
            </div>
            <div className="field">
                <label htmlFor="message">Message</label>
                <InputText id="message" type="text" />
            </div>
            <Button label="Submit" className="mt-2" />
        </form>
    );

    useEffect(() => {
        if (sidebarVisible && formContainerRef.current) {
            const iframe = document.createElement('iframe');
            iframe.src = `https://share.hsforms.com/1XMAqwXNVSe22fSfGZG5kPgqajz9`;
            iframe.width = '100%';
            iframe.height = '500px';
            iframe.style.border = 'none';
            formContainerRef.current.innerHTML = '';
            formContainerRef.current.appendChild(iframe);
        }
    }, [sidebarVisible]);

    const scrollToSection = (ref: React.MutableRefObject<null>) => {
        if (ref.current) {
            (ref.current as HTMLElement).scrollIntoView({ behavior: 'smooth' });
        }
    };

    const menuItems = [
        { label: 'HOME', command: () => scrollToSection(homeRef) },
        { label: 'TALK TO ANDR3W', command: () => scrollToSection(demoRef) },
        { label: 'INDUSTRIES', command: () => scrollToSection(industriesRef) },
        { label: 'INBOUND CALLS', command: () => scrollToSection(inboundCallsRef) },
        { label: 'OUTBOUND CALLS', command: () => scrollToSection(outboundCallsRef) },
        { label: 'PRICING', command: () => scrollToSection(pricingRef) },
        { label: 'TESTIMONIALS', command: () => scrollToSection(testimonialRef) },
        { label: 'FAQ', command: () => scrollToSection(faqRef) }
    ];

    const useCases = [
        { title: "Call Centers", description: "Replace a call center or outbound SDR team." },
        { title: "SaaS Software Sales", description: "Answer questions, warm up prospects, schedule demos 24/7." },
        { title: "Restaurants", description: "Answer inquiries, provide menu information, and take reservations 24/7." },
        { title: "Contractors", description: "Answer questions, prequalify leads, make reminder calls, and schedule on-site estimates efficiently." },
        { title: "Real Estate Agencies", description: "Qualify potential buyers/renters, schedule property viewings, and answer property-specific questions." },
        { title: "Auto Dealerships", description: "Handle initial inquiries, schedule test drives, and provide vehicle information round the clock." },
        { title: "Financial Services", description: "Answer basic account queries, schedule appointments with advisors, and provide product information." },
        { title: "Educational Institutions", description: "Handle admission inquiries, provide course information, and schedule campus tours." },
        { title: "Fitness Centers", description: "Manage class bookings, answer membership queries, and schedule personal training sessions." },
        { title: "Business Development", description: "Take surveys, collect feedback, request information, and more." },
        { title: "Reminders & Notifications", description: "Automate client reminder calls, appointments, schedule updates, and other notificaitons." },
        { title: "All Industries", description: "If your business has a phone, ANDR3W can save you time and money." }
    ];

    const testimonials = [
        { name: "Nick Bennett", title: "CRO of Projul", content: "Constantly hiring, training and retaining SDRs to scale our outbound program was a was a massive undertaking until Andr3w. Now we're making 7000+ calls per day and converting more leads." },
        { name: "N Clayson", title: "CCO - Customer Success", content: "Andr3w has transformed our customer support. The 24/7 availability of ANDR3W.ai has significantly improved our customer satisfaction scores. Andr3w is like having a tireless, knowledgeable team member." },
    ];

    const testimonialTemplate = (item: { name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; title: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; content: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }) => {
        return (
            <div className="p-3 border-1 surface-border border-round m-2 text-center">
                <h5>{item.name}</h5>
                <p>{item.title}</p>
                <p className="line-height-3 mb-3">{item.content}</p>
            </div>
        );
    };

    return (
        <div className="space-console" ref={homeRef}>
            <Toast ref={toast} />
            <div className="flex justify-content-center">
                <div className="flex-grow-1" style={{ maxWidth: '1440px', width: '100%' }}>
                    <Menubar model={menuItems} className="bg-black-alpha-90 fixed top-0 left-0 w-full z-5" />

                    <header className="console-header flex align-items-center justify-content-between p-3 mt-6">
                        <div className="flex align-items-center mr-6" >
                            <Andr3wActiveWordLogo />
                            {/* <FullWordLogo className="andr3w-full-word-logo mr-6" style={{ width: '350px', height: 'auto' }} /> */}
                        </div>
                    </header>

                    <main className="px-3 py-3" style={{ minWidth: '320px', maxWidth: '1440px', width: '100%' }}>
                        <section className="hero-section flex flex-column lg:flex-row p-3 align-items-center">
                            <div className="flex-grow-1 lg:w-6 mb-4 lg:mb-0">
                                <h2 className="text-xl font-bold mb-4">Still answering your own phones?</h2>
                                <h2 className="text-xl font-bold mb-6">Does hiring and training SDRs for outbound calls give you a headache?</h2>
                                <h3 className="text-lg">Introducing ANDR3W.</h3>
                                <Button label="Request Demo" className="p-button-outlined mt-3" onClick={openContactForm} data-augmented-ui="tl-clip tr-clip" />
                            </div>
                            <div className="flex-grow-1 lg:w-6" style={{ width: '100%', height: 'auto' }}>
                                <Andr3wHUD />
                            </div>
                        </section>

                        <section className="flex flex-column m-3 mt-6 common-aug-mixin common-aug-br-mixin" data-augmented-ui="tl-clip tr-clip-x br-2-clip-x b-clip-x bl-2-clip-y border">
                            <div className="flex flex-column align-items-center text-center md:text-left md:align-items-start px-4 mb-4">
                                <h2 className="text-4xl font-bold mb-4">Scale your business</h2>
                                <h2 className="text-4xl font-bold mb-4">Save time</h2>
                                <h2 className="text-4xl font-bold mb-4">Reduce your costs</h2>
                                <p className="text-xl mb-4">ANDR3W.ai is an advanced conversational AI system designed to supercharge your customer engagement. From answering inquiries and scheduling appointments to SDR sales and cold calling, ANDR3W works 24/7 to drive your business forward.</p>
                                <Button label="Initiate First Contact" className="p-button-md m-4" onClick={openContactForm} data-augmented-ui="bl-clip tr-clip" />
                            </div>
                        </section>
                        <div ref={demoRef} />
                        <section className="demo-section flex flex-column m-3 p-3 mt-6">
                            <h2 className="text-3xl font-bold mb-2">Talk with ANDR3W [Demos]</h2>
                            <p className="mb-4">Experience ANDR3W's capabilities firsthand with our interactive demos</p>
                            <div className="grid">
                                <div className="col-12 lg:col-4 flex align-items-center justify-content-center">
                                    <div className="hidden lg:flex align-items-center justify-content-center" style={{ width: '100%', height: '300px', position: 'relative' }}>
                                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: '100%', maxHeight: '100%' }}>
                                            <Andr3wActiveFaceLogo />
                                        </div>
                                    </div>
                                </div>
                                {demoData.map((demo, index) => (
                                    <div key={index} className="col-12 md:col-6 lg:col-4 p-2">
                                        <DemoBox
                                            title={demo.title}
                                            description={demo.description}
                                            onCallClick={(title, phoneNumber) => {
                                                setSelectedDemoTitle(title);
                                                setSelectedAcsPhoneNumber(phoneNumber);
                                                setDemoSidebarVisible(true);
                                            }}
                                            demoVideoUrl={demo.videoUrl}
                                        />
                                    </div>
                                ))}
                            </div>
                        </section>
                        <div ref={industriesRef} />
                        <section className="use-cases-section p-3 mt-6">
                            <h3 className="text-3xl font-bold mb-4">ANDR3W is Proficient in any Industry</h3>
                            <div className="flex flex-wrap">
                                {useCases.map((useCase, index) => (
                                    <div key={index} className="w-full sm:w-6 lg:w-3 p-2">
                                        <Card title={useCase.title} className={`use-case-card p-3 h-full common-aug-mixin ${index % 4 === 0 ? 'common-aug-tl-mixin' : index % 4 === 1 ? 'common-aug-tr-mixin' : index % 4 === 2 ? 'common-aug-bl-mixin' : 'common-aug-br-mixin'}`} data-augmented-ui="tl-clip t-clip-x tr-clip br-clip b-clip-x bl-clip border">
                                            {useCase.description}
                                        </Card>
                                    </div>
                                ))}
                            </div>
                        </section>
                        <div ref={inboundCallsRef} />
                        <section className="flex flex-column m-3 p-3 mt-8 common-aug-mixin common-aug-br-mixin" data-augmented-ui="tl-clip t-rect-xy tr-clip-y br-clip-inset b-clip bl-clip border">
                            <h2 className="text-4xl font-bold mb-4">Let ANDR3W Handle Inbound Calls</h2>
                            <div className="flex flex-column p-3">
                                <h3 className="text-2xl mb-3">Transform Customer Interactions with Intelligent Automation</h3>
                                <p className="mb-3">Welcome to a new era of customer engagement. ANDR3W.ai handles inbound calls with unmatched efficiency, providing your customers with immediate, accurate, and helpful responses.</p>

                                <div className="flex flex-column md:flex-row">
                                    <div className="flex-grow-1 md:w-6 mb-4 md:mb-0 md:mr-2">
                                        <h4 className="text-xl mb-2 mt-4 text-left text-300">Features:</h4>
                                        <ul className="list-none p-0 m-0 text-left">
                                            <li className="flex align-items-center mb-2">
                                                <i className="pi pi-check-circle text-300 mr-2"></i>
                                                <span className="font-bold">24/7 Availability</span>
                                            </li>
                                            <li className="flex align-items-center mb-2">
                                                <i className="pi pi-check-circle text-300 mr-2"></i>
                                                <span className="font-bold">Intelligent Routing</span>
                                            </li>
                                            <li className="flex align-items-center mb-2">
                                                <i className="pi pi-check-circle text-300 mr-2"></i>
                                                <span className="font-bold">Objective Based Personal Responses</span>
                                            </li>
                                            <li className="flex align-items-center mb-2">
                                                <i className="pi pi-check-circle text-300 mr-2"></i>
                                                <span className="font-bold">Real-Time Analytics</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="flex-grow-1 md:w-6 md:ml-2">
                                        <h4 className="text-xl mb-2 text-left text-300">Benefits:</h4>
                                        <ul className="list-none p-0 m-0 text-left">
                                            <li className="flex align-items-center mb-2">
                                                <i className="pi pi-star text-300 mr-2"></i>
                                                <span className="font-bold">Enhance Customer Satisfaction</span>
                                            </li>
                                            <li className="flex align-items-center mb-2">
                                                <i className="pi pi-star text-300 mr-2"></i>
                                                <span className="font-bold">Reduce Hold Times</span>
                                            </li>
                                            <li className="flex align-items-center mb-2">
                                                <i className="pi pi-star text-300 mr-2"></i>
                                                <span className="font-bold">Lower Operation Costs</span>
                                            </li>
                                            <li className="flex align-items-center mb-2">
                                                <i className="pi pi-star text-300 mr-2"></i>
                                                <span className="font-bold">Scalable Solutions</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div ref={outboundCallsRef} />
                        <section className="flex flex-column m-3 p-3 mt-6 common-aug-mixin common-aug-br-mixin" data-augmented-ui="tl-clip-x t-clip-xy tr-clip br-clip-x b-clip-y bl-clip-inset border">
                            <h2 className="text-4xl font-bold mb-4">Automate Outbound Calls with ANDR3W</h2>
                            <div className="flex flex-column p-3">
                                <h3 className="text-2xl mb-3">Supercharge Your Outbound Campaigns with AI Precision</h3>
                                <p className="mb-3">Take your outbound call campaigns to the next level with ANDR3W.ai. Our advanced AI technology ensures that every call is timely, relevant, and engaging.</p>
                                <div className="flex flex-column md:flex-row">
                                    <div className="flex-grow-1 md:w-6 mb-4 md:mb-0 md:mr-2">
                                        <h4 className="text-xl mb-2 text-left text-300">Features:</h4>
                                        <ul className="list-none p-0 m-0 text-left">
                                            <li className="flex align-items-center mb-2">
                                                <i className="pi pi-check-circle text-300 mr-2"></i>
                                                <span className="font-bold">Automated Outreach</span>
                                            </li>
                                            <li className="flex align-items-center mb-2">
                                                <i className="pi pi-check-circle text-300 mr-2"></i>
                                                <span className="font-bold">Lead Nurturing</span>
                                            </li>
                                            <li className="flex align-items-center mb-2">
                                                <i className="pi pi-check-circle text-300 mr-2"></i>
                                                <span className="font-bold">Personalized Messaging</span>
                                            </li>
                                            <li className="flex align-items-center mb-2">
                                                <i className="pi pi-check-circle text-300 mr-2"></i>
                                                <span className="font-bold">Detailed Call Reports</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="flex-grow-1 md:w-6 md:ml-2">
                                        <h4 className="text-xl mb-2 text-left text-300">Benefits:</h4>
                                        <ul className="list-none p-0 m-0 text-left mb-6">
                                            <li className="flex align-items-center mb-2">
                                                <i className="pi pi-star text-300 mr-2"></i>
                                                <span className="font-bold">Increase Conversion Rates</span>
                                            </li>
                                            <li className="flex align-items-center mb-2">
                                                <i className="pi pi-star text-300 mr-2"></i>
                                                <span className="font-bold">Boost Productivity</span>
                                            </li>
                                            <li className="flex align-items-center mb-2">
                                                <i className="pi pi-star text-300 mr-2"></i>
                                                <span className="font-bold">Improve Lead Quality</span>
                                            </li>
                                            <li className="flex align-items-center mb-2">
                                                <i className="pi pi-star text-300 mr-2"></i>
                                                <span className="font-bold">Cost-Efficient Campaigns</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div ref={pricingRef} />
                        <section className="flex flex-column p-3 mt-6">
                            <h2 className="text-3xl font-bold mb-4">Simple, Transparent Pricing</h2>
                            <p className="mb-4">Invest in the Future of Customer Engagement</p>
                            <div className="flex flex-wrap">
                                <div className="w-full md:w-4 p-2">
                                    <Card title="COR3" subTitle="Perfect for small businesses and startups" className="pricing-card p-3 h-full common-aug-mixin" data-augmented-ui="tl-clip t-clip-x tr-clip br-clip b-clip-x bl-clip border">
                                        <h3 className="text-2xl mb-3">$1K mo</h3>
                                        <ul className="list-none p-0 m-0 mt-6 text-left">
                                            <li className="flex align-content-start mb-2 ">
                                                <i className="pi pi-check mr-2"></i>
                                                <span className="font-bold">~ Compare to 1+ Human Employee</span>
                                            </li>
                                            <li className="flex align-content-start mb-2">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>Single ANDR3W agent <span className="text-300"><br />[role / use case]</span></span>
                                            </li>
                                            <li className="flex align-content-start mb-2 ">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>Inbound calls handling</span>
                                            </li>
                                            <li className="flex align-content-start mb-2 ">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>1 concurrent calls limit</span>
                                            </li>
                                            <li className="flex align-content-start mb-2">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>Standard analytics</span>
                                            </li>
                                            <li className="flex align-items-center justify-content-between my-3">
                                                <span className="font-bold text-4xl mr-2">+</span>
                                                <div className="border-3 border-yellow-600 flex-grow-1 mt-1"></div>
                                            </li>
                                            <span className="text-300"><i className="pi pi-bolt"></i> ANDR3W's fuel <i className="pi pi-bolt"></i></span>
                                            <div className="font-bold py-2">3000 Minute Block:  +$600 ($0.20/min)</div>
                                            <span className="text-300">[~50 hours of ANDR3W call time that never expires & auto renews at 10%]</span>
                                            <li className="flex align-items-center justify-content-between my-3">
                                                <span className="font-bold text-4xl mr-2">+</span>
                                                <div className="border-3 border-gray-900 flex-grow-1 mt-1"></div>
                                            </li>
                                            <div className="font-bold">AGENT TRAINING: +$4500 </div>
                                            <span className="text-300">[data, personality, role & objectives for inital training or new training requests]</span>
                                        </ul>
                                    </Card>
                                </div>
                                <div className="w-full md:w-4 p-2">
                                    <Card title="PRIM3" subTitle="For scaling businesses that need more advanced features and higher volume of calls" className="pricing-card p-3 h-full common-aug-mixin" data-augmented-ui="tl-clip t-clip-x tr-clip br-clip b-clip-x bl-clip border">
                                        <h3 className="text-2xl mb-3">$5K mo</h3>
                                        <ul className="list-none p-0 m-0 mt-6 text-left">
                                            <li className="flex align-content-start mb-2 ">
                                                <i className="pi pi-check mr-2"></i>
                                                <span className="font-bold">~ Compare to 5+ Human Employees</span>
                                            </li>
                                            <li className="flex align-content-start mb-2">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>Up to five ANDR3W agents <span className="text-300"><br />[role(s) / use case(s)]</span></span>
                                            </li>
                                            <li className="flex align-content-start mb-2 ">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>Inbound calls handling</span>
                                            </li>
                                            <li className="flex align-content-start mb-2 ">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>Outbound calls handling</span>
                                            </li>
                                            <li className="flex align-content-start mb-2 ">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>5 concurrent calls limit per Agent</span>
                                            </li>
                                            <li className="flex align-content-start mb-2">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>Enhanced analytics</span>
                                            </li>
                                            <li className="flex align-content-start mb-2">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>Outbound call lists support</span>
                                            </li>
                                            <li className="flex align-content-start mb-2">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>Outbound call scheduler</span>
                                            </li>
                                            <li className="flex align-content-start mb-2">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>Google calendar integration</span>
                                            </li>
                                            <li className="flex align-items-center justify-content-between my-3">
                                                <span className="font-bold text-4xl mr-2">+</span>
                                                <div className="border-3 border-yellow-600 flex-grow-1 mt-1"></div>
                                            </li>
                                            <span className="text-300"><i className="pi pi-bolt"></i> ANDR3W's fuel <i className="pi pi-bolt"></i></span>
                                            <div className="font-bold py-2">10000 Minute Block:  +$1800 ($0.18/min)</div>
                                            <span className="text-300">[~166 hours of ANDR3W call time that never expires & auto renews at 10%]</span>
                                            <li className="flex align-items-center justify-content-between my-3">
                                                <span className="font-bold text-4xl mr-2">+</span>
                                                <div className="border-3 border-gray-900 flex-grow-1 mt-1"></div>
                                            </li>
                                            <div className="font-bold">PER AGENT TRAINING: +$7500 </div>
                                            <span className="text-300">[data, personality, role & objectives for inital training or new training requests]</span>
                                        </ul>
                                    </Card>
                                </div>
                                <div className="w-full md:w-4 p-2">
                                    <Card title="OMNI" subTitle="Tailored for companies and enterprises with large call volumes or call centers and need advanced integrations" className="pricing-card p-3 h-full common-aug-mixin" data-augmented-ui="tl-clip t-clip-x tr-clip br-clip b-clip-x bl-clip border">
                                        <h3 className="text-2xl mb-3">$10K mo</h3>
                                        <ul className="list-none p-0 m-0 mt-6 text-left">
                                            <li className="flex align-content-start mb-2 ">
                                                <i className="pi pi-check mr-2"></i>
                                                <span className="font-bold">~ Compare to a call center</span>
                                            </li>
                                            <li className="flex align-content-start mb-2">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>Unlimited ANDR3W agents<span className="text-300"><br />[role(s) / use case(s)]</span></span>
                                            </li>
                                            <li className="flex align-content-start mb-2 ">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>Inbound calls handling</span>
                                            </li>
                                            <li className="flex align-content-start mb-2 ">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>Outbound calls handling</span>
                                            </li>
                                            <li className="flex align-content-start mb-2 ">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>Up to 100 concurrent calls</span>
                                            </li>
                                            <li className="flex align-content-start mb-2">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>Custom analytics & reports</span>
                                            </li>
                                            <li className="flex align-content-start mb-2">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>Outbound call lists support</span>
                                            </li>
                                            <li className="flex align-content-start mb-2">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>Outbound call scheduler</span>
                                            </li>
                                            <li className="flex align-content-start mb-2">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>Google calendar integration</span>
                                            </li>
                                            <li className="flex align-content-start mb-2">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>HubSpot integration</span>
                                            </li>
                                            <li className="flex align-content-start mb-2">
                                                <i className="pi pi-check mr-2"></i>
                                                <span>A/B testing *</span>
                                            </li>
                                            <li className="flex align-items-center justify-content-between my-3">
                                                <span className="font-bold text-4xl mr-2">+</span>
                                                <div className="border-3 border-yellow-600 flex-grow-1 mt-1"></div>
                                            </li>
                                            <span className="text-300"><i className="pi pi-bolt"></i> ANDR3W's fuel <i className="pi pi-bolt"></i></span>
                                            <div className="font-bold py-2">100000 Minute Block:  +$15000 ($0.15/min)</div>
                                            <span className="text-300">[~1666 hours of ANDR3W call time that never expires & auto renews at 10%]</span>
                                            <li className="flex align-items-center justify-content-between my-3">
                                                <span className="font-bold text-4xl mr-2">+</span>
                                                <div className="border-3 border-gray-900 flex-grow-1 mt-1"></div>
                                            </li>
                                            <div className="font-bold">PER AGENT TRAINING: +$10500 </div>
                                            <span className="text-300">[data, personality, role & objectives for inital training or new training requests]</span>
                                        </ul>
                                    </Card>
                                </div>
                            </div>
                        </section>
                        <section className="flex flex-column justify-content-center align-items-center p-3 m-3 mt-6 common-aug-mixin common-aug-bl-mixin" data-augmented-ui="tl-2-clip-y tr-2-clip-x br-clip b-clip-x bl-clip-x border">
                            <h3 className="text-2xl font-bold m-4">Ready to save time and money?</h3>
                            <Button label="Make Contact" className="mb-4 p-button-lg p-3" data-augmented-ui="tr-clip bl-clip" onClick={openContactForm} />
                        </section>
                        <div ref={testimonialRef} />
                        <section className="p-0 mt-6">
                            <h2 className="text-3xl font-bold mb-4">Hear from Our Satisfied Clients</h2>
                            <Carousel value={testimonials} numVisible={1} numScroll={1} className="border-0" circular autoplayInterval={9000} itemTemplate={testimonialTemplate} />
                        </section>
                        <div ref={faqRef} />
                        <section className="flex flex-column m-3 p-3 mt-6 common-aug-mixin" data-augmented-ui="tl-2-clip-x t-clip-x tr-clip br-2-clip-x b-clip bl-clip border">
                            <h2 className="accordion text-3xl font-bold mb-4">Common Questions Answered</h2>
                            <Accordion className="pricing-card p-3 h-full text-left">
                                <AccordionTab header="How does ANDR3W.ai integrate with my existing systems?">
                                    <p>ANDR3W.ai accepts exported call list data from any CRM and ERP systems. Our team will assist you with the setup and integration process.</p>
                                </AccordionTab>
                                <AccordionTab header="Is my data secure with ANDR3W.ai?">
                                    <p>Absolutely. We use state-of-the-art encryption and security protocols to ensure your data is safe.</p>
                                </AccordionTab>
                                <AccordionTab header="Can I customize the responses ANDR3W.ai provides?">
                                    <p>Yes, ANDR3W.ai allows for extensive customization to match your brand's voice and requirements.</p>
                                </AccordionTab>
                            </Accordion>
                        </section>

                        <section className="flex flex-column p-3 m-3 mt-6 benefits-section common-aug-mixin common-aug-tr-mixin" data-augmented-ui="tl-2-clip-x tr-2-clip-y br-clip b-clip-x bl-2-clip-y border">
                            <h3 className="text-2xl font-bold m-4">Why Choose ANDR3W?</h3>
                            <ul className="list-disc p-0 p-3 m-3 text-left">
                                <li className="mb-2"><span className="font-bold">24/7 Availability:</span><span className="ml-3 text-300">Never miss a customer inquiry</span></li>
                                <li className="mb-2"><span className="font-bold">Consistent Performance:</span> <span className="ml-3 text-300">Maintain quality across all interactions</span></li>
                                <li className="mb-2"><span className="font-bold">Scalability:</span><span className="ml-3 text-300">Easily handle increased call volumes</span></li>
                                <li className="mb-2"><span className="font-bold">Reduce Costs:</span><span className="ml-3 text-300">60% cheaper than a human</span></li>
                                <li className="mb-2"><span className="font-bold">Detailed Analytics:</span><span className="ml-3 text-300">Gain insights to refine your customer engagement strategy</span></li>
                                <li className="mb-2"><span className="font-bold">Accelerated Growth:</span><span className="ml-3 text-300">Grow your business quickly with accelerated lead generation</span></li>
                            </ul>
                        </section>

                        <section className="flex flex-column justify-content-center align-items-center p-3 m-3 mt-6 common-aug-mixin common-aug-bl-mixin" data-augmented-ui="tl-2-clip-y tr-2-clip-x br-clip b-clip-x bl-clip-x border">
                            <h3 className="text-2xl font-bold m-4">Ready to get ANDR3W working for you?</h3>
                            <Button label="Make Contact" className="mb-4 p-button-lg p-3" data-augmented-ui="tr-clip bl-clip" onClick={openContactForm} />
                        </section>

                    </main>

                    <footer className="flex align-items-center justify-content-between p-3 mt-6 common-aug-mixin common-aug-bl-mixin" style={{ width: '100%', height: 'auto' }} data-augmented-ui="tl-clip tr-clip both">
                        <div className="flex align-items-center">
                            <div onClick={handleFooterLogoClick} style={{ cursor: 'pointer' }}>
                                <Andr3wActiveFooterLogo />
                            </div>
                            <p className="m-0 ml-6">
                                © 2024 ANDR3W.ai | All Rights Reserved |{' '}
                                <span
                                    className="text-yellow-500 cursor-pointer hover:text-yellow-300"
                                    onClick={openPrivacyPolicy}
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Privacy Policy
                                </span>{' '}
                                |{' '}
                                <span
                                    className="text-yellow-500 cursor-pointer hover:text-yellow-300"
                                    onClick={openTermsOfService}
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Terms of Service
                                </span>
                            </p>
                        </div>
                    </footer>
                </div>

                <Sidebar
                    visible={sidebarVisible}
                    position="right"
                    onHide={() => setSidebarVisible(false)}
                    className="contact-form-sidebar p-sidebar-lg common-aug-overlay-mixin common-aug-tl-mixin"
                    data-augmented-ui="tl-2-clip-y t-clip-x tr-clip b-clip-x bl-2-clip-x border"
                    style={{ width: '400px' }}
                    header="|     Contact Us"
                >
                    <HubSpotContactUsForm location="sidebar" />
                </Sidebar>

                <SecretConsole
                    visible={secretConsoleVisible}
                    onHide={() => setSecretConsoleVisible(false)}
                    onDexisCommand={handleDexisCommand}
                    dexisPhoneNumber={demoPhoneNumbers['DEXIS Demo']}
                />

                <CallDemoSidebar
                    visible={demoSidebarVisible}
                    onHide={() => setDemoSidebarVisible(false)}
                    acsPhoneNumber={selectedAcsPhoneNumber}
                    demoTitle={selectedDemoTitle}
                    onOpenTermsOfService={openTermsOfService}
                />
                <Dialog
                    header="Privacy Policy"
                    visible={privacyDialogVisible}
                    onHide={() => setPrivacyDialogVisible(false)}
                    className="common-aug-overlay-mixin w-full sm:w-11 md:w-8 lg:w-6"
                    data-augmented-ui="tl-clip tr-clip br-clip bl-clip both"
                    contentClassName="bg-black-alpha-90"
                    headerClassName="p-3 sm:p-4"
                    contentStyle={{ padding: '0' }}
                >
                    <div className="p-3 sm:p-4">
                        <ScrollPanel style={{ width: '100%', height: '70vh' }} className="custom-scrollbar">
                            <PrivacyPolicy />
                        </ScrollPanel>
                    </div>
                </Dialog>
                <Dialog
                    header="Terms of Service"
                    visible={tosDialogVisible}
                    onHide={() => setTosDialogVisible(false)}
                    className="common-aug-overlay-mixin w-full sm:w-11 md:w-8 lg:w-6"
                    data-augmented-ui="tl-clip tr-clip br-clip bl-clip both"
                    contentClassName="bg-black-alpha-90"
                    headerClassName="p-3 sm:p-4"
                    contentStyle={{ padding: '0' }}
                >
                    <div className="p-3 sm:p-4">
                        <ScrollPanel style={{ width: '100%', height: '70vh' }} className="custom-scrollbar">
                            <TermsOfService />
                        </ScrollPanel>
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

export default HomePage;